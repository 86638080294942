import moment from 'moment';
import i18n from '@/utils/localization';

export const EVENT_TYPE_BROADCAST = 'broadcasting_event';
export const EVENT_TYPE_COLLABORATIVE = 'collaborative_group_event';
export const EVENT_TYPE_PRIVATE = 'private_session';
export const EVENT_TYPE_LOCAL = 'studio_event';
export const EVENT_TYPE_MEETING = 'meeting';

const EVENT_TYPE_LABELS = {
  broadcasting_event: 'EVENTS.LABEL.TYPE_BROADCAST',
  collaborative_group_event: 'EVENTS.LABEL.TYPE_COLLABORATIVE',
  private_session: 'EVENTS.LABEL.TYPE_PRIVATE',
  studio_event: 'EVENTS.LABEL.TYPE_LOCAL',
};

const EVENT_TYPE_ICON_CLASSES = {
  broadcasting_event: 'fal fa-camcorder c-mustard',
  collaborative_group_event: 'fal fa-users c-brown',
  private_session: 'fal fa-user-shield c-light-green',
  studio_event: 'fal fa-map-marker-alt c-blue',
};

const ZOOM_START_EVENT_TYPE_LABELS = {
  broadcasting_event: 'EVENTS.BUTTON.START_ZOOM_WEBINAR',
  collaborative_group_event: 'EVENTS.BUTTON.START_ZOOM_MEETING',
  private_session: 'EVENTS.BUTTON.START_ZOOM_MEETING',
  studio_event: '',
};

const ZOOM_SCHEDULE_EVENT_TYPE_LABELS = {
  broadcasting_event: 'EVENTS.BUTTON.SCHEDULE_ZOOM_WEBINAR',
  collaborative_group_event: 'EVENTS.BUTTON.SCHEDULE_ZOOM_MEETING',
  private_session: 'EVENTS.BUTTON.SCHEDULE_ZOOM_MEETING',
  studio_event: '',
};

export const EVENT_TYPES = [
  {
    label: 'EVENTS.LABEL.TYPE_BROADCAST',
    code: EVENT_TYPE_BROADCAST,
    icon: EVENT_TYPE_ICON_CLASSES[EVENT_TYPE_BROADCAST],
  },
  {
    label: 'EVENTS.LABEL.TYPE_COLLABORATIVE',
    code: EVENT_TYPE_COLLABORATIVE,
    icon: EVENT_TYPE_ICON_CLASSES[EVENT_TYPE_COLLABORATIVE],
  },
  {
    label: 'EVENTS.LABEL.TYPE_PRIVATE',
    code: EVENT_TYPE_PRIVATE,
    icon: EVENT_TYPE_ICON_CLASSES[EVENT_TYPE_PRIVATE],
  },
  {
    label: 'EVENTS.LABEL.TYPE_LOCAL',
    code: EVENT_TYPE_LOCAL,
    icon: EVENT_TYPE_ICON_CLASSES[EVENT_TYPE_LOCAL],
  },
];

const EVENT_STREAM_OPTIONS = {
  broadcasting_event: {
    zoom: true,
    webinar: true,
    browser: true,
  },
  collaborative_group_event: {
    zoom: true,
    webinar: false,
    browser: false,
  },
  private_session: {
    zoom: true,
    webinar: false,
    browser: true,
  },
  studio_event: {
    zoom: false,
    webinar: false,
    browser: false,
  },
  meeting: {
    zoom: true,
    webinar: false,
    browser: false,
  },
};

export const DAYS_IN_WEEK = [
  {
    value: 0,
    display_name: i18n.t('GENERAL.CALENDAR.SUNDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.SUNDAY').toString(),
  },
  {
    value: 1,
    display_name: i18n.t('GENERAL.CALENDAR.MONDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.MONDAY').toString(),
  },
  {
    value: 2,
    display_name: i18n.t('GENERAL.CALENDAR.TUESDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.TUESDAY').toString(),
  },
  {
    value: 3,
    display_name: i18n.t('GENERAL.CALENDAR.WEDNESDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.WEDNESDAY').toString(),
  },
  {
    value: 4,
    display_name: i18n.t('GENERAL.CALENDAR.THURSDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.THURSDAY').toString(),
  },
  {
    value: 5,
    display_name: i18n.t('GENERAL.CALENDAR.FRIDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.FRIDAY').toString(),
  },
  {
    value: 6,
    display_name: i18n.t('GENERAL.CALENDAR.SATURDAY_SHORT').toString(),
    display_name_long: i18n.t('GENERAL.CALENDAR.SATURDAY').toString(),
  },
];

export const REGISTRATION_LIMITS = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  25,
  30,
  35,
  40,
  45,
  50,
  60,
  70,
  80,
  90,
  100,
  125,
  150,
  175,
  200,
  250,
  300,
  350,
  400,
  500,
  600,
  700,
  800,
  900,
  1000,
  1500,
  2000,
];
export const DURATION_OPTIONS = [
  {
    value: 0,
    label: 'Do not close',
  },
  {
    value: 1,
    label: '1 minute',
  },
  {
    value: 5,
    label: '5 minutes',
  },
  {
    value: 10,
    label: '10 minutes',
  },
  {
    value: 15,
    label: '15 minutes',
  },
  {
    value: 20,
    label: '20 minutes',
  },
  {
    value: 30,
    label: '30 minutes',
  },
  {
    value: 45,
    label: '45 minutes',
  },
  {
    value: 60,
    label: '1 hour',
  },
  {
    value: 90,
    label: '1 hour 30 min',
  },
  {
    value: 120,
    label: '2 hours',
  },
  {
    value: 150,
    label: '2 hours 30 min',
  },
  {
    value: 180,
    label: '3 hours',
  },
  {
    value: 240,
    label: '4 hours',
  },
  {
    value: 300,
    label: '5 hours',
  },
  {
    value: 480,
    label: '8 hours',
  },
  {
    value: 720,
    label: '12 hours',
  },
  {
    value: 1440,
    label: '1 day',
  },
  {
    value: 2880,
    label: '2 days',
  },
  {
    value: 4320,
    label: '3 days',
  },
  {
    value: 7200,
    label: '5 days',
  },
  {
    value: 14400,
    label: '10 days',
  },
  {
    value: 21600,
    label: '15 days',
  },
  {
    value: 43200,
    label: '30 days',
  },
];

export const getEventStreamOptions = event_type => {
  return EVENT_STREAM_OPTIONS[event_type];
};

export const getEventTypeLabelKey = event_type => {
  return EVENT_TYPE_LABELS[event_type];
};

export const getEventTypeIconClasses = event_type => {
  return EVENT_TYPE_ICON_CLASSES[event_type];
};

export const getZoomEventTypeLabel = (event_type, isSoon) => {
  if (isSoon) {
    return ZOOM_START_EVENT_TYPE_LABELS[event_type];
  } else {
    return ZOOM_SCHEDULE_EVENT_TYPE_LABELS[event_type];
  }
};

export const getDayName = dayInWeek => {
  if (dayInWeek === undefined || dayInWeek === null) return '';
  return DAYS_IN_WEEK.filter(d => {
    return d.value == dayInWeek;
  })[0].display_name_long;
};

export const shortDate = (date, format) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format('ll');
};

export const longDateTime = (date, format) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format('lll');
};

export const shortTime = (date, format) => {
  if (format) {
    return moment(date).format(format);
  }
  return moment(date).format('hh:mm A');
};

export const humanizeDuration = (amount, unit) => {
  return moment.duration(amount, unit).humanize();
};
